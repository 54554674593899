import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/Seo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(4),
        },
    })
);

const title = "You're signed up";
const description = title;

const SignupConfirmed: React.FC<PageProps> = (props) => {
    const classes = useStyles();

    return (
        <Layout>
            <SEO title={title} description={description} path={props.path} />
            <Section content first last>
                <div className={classes.title}>
                    <h1>{title}</h1>
                </div>
                <p>Thanks for entering your email and signing up.</p>
                <p>
                    When I post new articles on my blog, you'll get the full
                    article delivered to your inbox. I aim to write a couple of
                    posts each month. Feedback is always appreciated so don't
                    hesitate to reply.
                </p>
                <p>
                    Meanwhile, you can read my{" "}
                    <Link to="/blog">existing articles.</Link>
                </p>
                <p>-- Tim Edgar</p>
            </Section>
        </Layout>
    );
};

export default SignupConfirmed;
